import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import CasesGrid from '../../Cases';

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  min-height: 100vh;
  padding: 300px 0;

  ${media.lessThan('medium')`
    padding: 62px 0;
  `}
`;

export default ({ cases }) => {
  return (
    <Section id="cases">
      <CasesGrid items={cases?.edges} />
    </Section>
  );
};
