import React from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import media from "styled-media-query";
import { useViewportScroll, useTransform, motion } from "framer-motion";
import bgImg from '../../../images/banner.jpg'

import RevealWord from "../../RevealWord";

const Section = styled.section`
  width: 100%;
  height: 100vh;
  min-height: 700px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .background-image {
    width: 100%;
    height: 100vh;
  }
`;

const Content = styled(motion.main)`
  position: relative;
  z-index: 2;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Title = styled.h1`
  line-height: 73px;
  font-size: 61px;
  font-family: "Krona One", sans-serif;
  font-weight: 400;
  letter-spacing: 5px;
  position: relative;
  margin-bottom: 40px;

  :after {
    content: "";
    width: 600px;
    height: 135px;
    border: 5px solid #8effac;
    position: absolute;
    top: 0;
    left: -20px;
    pointer-events: none;
    display: none;
  }

  ${media.lessThan("medium")`
    font-size: 10.1vw;
    line-height: 62px;
  `}
`;

const Cta = styled(motion.button)`
  font-size: 12px;
  text-transform: lowercase;
  font-weight: 400;
  letter-spacing: 2px;
  cursor: pointer;
  position: relative;
  background: none;
  border: 0;
  font-family: "Montserrat";

  :hover {
    :after {
      width: 100%;
    }
  }

  :after {
    content: "";
    width: 50%;
    height: 1px;
    position: absolute;
    top: calc(100% + 10px);
    transition: all 800ms ease;
    left: 0;
    background: #8effac;
  }
`;

const Inner = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;

  ${media.lessThan("medium")`
    transform: translateY(-8vh);
  `}
`;

const Line = styled(motion.div)`
  width: 1px;
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  background: white;
`;

const ImageBackground = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export default () => {
  const { scrollYProgress } = useViewportScroll();

  const imageOut = useTransform(scrollYProgress, [0, 0.11], [1, 0]);
  const lineOut = useTransform(scrollYProgress, [0, 0.2], [0, -200]);

  return (
    <Section id="manifesto">
      <ImageBackground
        style={{ opacity: imageOut }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { bounce: 0, delay: 1.3 } }}
        exit={{ opacity: 0 }}
      >
        <img src={bgImg} alt="Background image" />
      </ImageBackground>
      <Content>
        <Inner>
          <>
            <Title>
              <RevealWord exitDelay={0} delay={1.4}>
                estratégias digitais
              </RevealWord>
              <RevealWord exitDelay={0.2} delay={1.6}>
                para impulsionar
              </RevealWord>
              <RevealWord exitDelay={0.4} delay={1.8}>
                seu negócio
              </RevealWord>
            </Title>

            <Cta
              onTap={() => navigate("/manifesto")}
              initial={{ opacity: 0, y: 10 }}
              animate={{
                opacity: 1,
                y: 0,
                transition: { delay: 2, type: "spring", bounce: 0.2 },
              }}
              exit={{ opacity: 0, y: -10, pointerEvents: "none" }}
              whileHover={{ "letter-spacing": "3px", x: -10 }}
              transition={{
                type: "spring",
                damping: 10,
                stiffness: 20,
              }}
            >
              Assista o manifesto completo
            </Cta>
          </>
        </Inner>
      </Content>

      {[
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
      ].map((item, index) => (
        <Line
          initial={{
            x: 100 * index,
            height: 0,
          }}
          animate={{
            height: Math.floor(5 + Math.random() * (60 + 1 - 5)),
            transition: {
              delay: 1.5 + index * 0.09,
              type: "spring",
              bounce: 0.1,
            },
          }}
          style={{ bottom: lineOut }}
        />
      ))}
    </Section>
  );
};
