import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

const Section = styled.section`
  width: 100%;
  padding: 250px 0;
  background: #2c2629;

  ${media.lessThan('medium')`
    padding: 64px 16px;
  `}
`;

const Container = styled.main`
  width: calc(100% - 200px - 10%);
  margin: 0;
  margin-left: auto;
  margin-right: 5%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 48px
  padding-left: 10vw;

  ${media.lessThan('medium')`
    width: 100%;
    padding-left: 0;
    margin-right: 0;
  `}
`;

const Block = styled.div`
  
`;

const Title = styled.h3`
  font-family: 'Krona One', sans-serif;
  font-size: 26px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 32px;
`;

const List = styled.ol`
  list-style: none;
  display: flex;
  flex-flow: column wrap;
`;

const Item = styled.li`
  margin: 18px 0;
  position: relative;
  transition: all 600ms ease;

  a {
    text-decoration: none;
  }

  :after {
    content: '';
    width: 0;
    height: 3px;
    background: #8effac;
    position: absolute;
    top: calc(100% + 4px);
    left: -10px;
    transition: all 600ms ease;
  }

  :hover {
    letter-spacing: 1px;
  }

  :hover:after {
    width: 50px;
  }
`;

export default ({ services }) => {
  return (
    <Section id="solucoes">
      <Container>
        {services?.edges?.map((category) => (
          <Block>
            <Title>.{category?.node?.title}</Title>
            <List>
              {category?.node?.services?.map((service) => (
                <Item key={service?.id}>
                  <Link to={`/cases/?filter=${service?.title}`}>
                    {service?.title}
                  </Link>
                </Item>
              ))}
            </List>
          </Block>
        ))}
      </Container>
    </Section>
  );
};
