import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

const Title = styled.h2`
  width: 100%;
  text-align: center;
  color: white;
  font-family: 'Krona One', sans-serif;
  font-size: 26px;
  font-weight: 400;
  text-transform: uppercase;
  margin-left: 10%;
  margin-bottom: 130px;
`

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;

  .background-image {
    width: 100%;
    height: 100vh;
  }
`;

const Container = styled.main`
  width: 100%;
  margin: 0;
  margin-left: auto;
  margin-right: 5%;
  display: flex;
  flex-flow: row wrap;
  padding-left: 10vw;
  margin-top: 80px;
  padding-bottom: 180px;

  ${media.lessThan('medium')`
    width: 100%;
    padding-left: 0;
    margin-right: 0;
  `}
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 60px;
  width: 80%;
  max-width: 1290px;
  margin: 0 auto;
  place-items: center;

  ${media.lessThan('medium')`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.lessThan('small')`
    grid-template-columns: repeat(1, 1fr);
  `}
`
const Tile = styled.div`
  background: transparent;
  height: 10vw;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  transition: all 400ms ease;

  ${media.lessThan('medium')`
    height: 20vw;
  `}

  ${media.lessThan('small')`
    height: 80vw;
  `}

  :hover {
    opacity:1;
    cursor: pointer;
  }

  img {
    width: 90%;
    height: 90%;
    object-fit: contain;
  }
`


export default ({customers}) => {
  return (
    <Section id="customers">
      <Container>
      <Title>Clientes</Title>
        <Grid>
          {customers.edges.map(item => (
            <Tile as="a" href={item.node.link} target="_blank" rel="noreferrer noopener" key={item.node.id}>
              <img
                className="image"
                src={item.node.logo.file.url}
                alt={item.node.title}
              />
            </Tile>
          ))}
        </Grid>
      </Container>
    </Section>
  );
};
