import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  padding: 300px 0;

  ${media.lessThan('medium')`
    padding: 64px 0;
  `}
`;

const Title = styled.h2`
  font-size: 60px;
  font-family: 'Krona One';
  text-transform: uppercase;
  font-weight: 100;
  margin-bottom: 60px;
  width: 100%;
  letter-spacing: 9px;

  ${media.lessThan('medium')`
    font-size: 32px;
    margin-bottom: 32px;
  `}
`;

const Container = styled.main`
  width: calc(100% - 200px - 10%);
  margin: 0;
  margin-left: auto;
  margin-right: 5%;
  display: flex;
  flex-flow: row wrap;
  padding-left: 10vw;

  ${media.lessThan('medium')`
    width: 100%;
    padding-left: 0;
    margin-right: 0;
    padding: 0 16px;
  `}
`;

const Texts = styled.div`
  width: 100%;
  max-width: 530px;
  margin-left: 7%;

  ${media.lessThan('medium')`
    margin-left: 0;
  `};

  p {
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 24px;
    margin-bottom: 28px;
    font-weight: 400;
  }
`;

export default ({ about }) => (
  <Section id="o-lab721">
    <Container>
      <Title>{about?.edges[0]?.node?.primeiroTitulo}</Title>
      <Texts
        dangerouslySetInnerHTML={{
          __html:
            about?.edges[0]?.node?.primeiroTexto?.childMarkdownRemark?.html,
        }}
      />
    </Container>
  </Section>
);
